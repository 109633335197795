
import PostsConfigsService from "@/modules/posts-configs/posts-configs-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.create(payload).then(response => {
                resolve({
                    message: "PostsConfigs criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "PostsConfigs atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.remove(id).then(response => {
                resolve({
                    message: "PostsConfigs removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.delete(id).then(response => {
                resolve({
                    message: "PostsConfigs deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.get(id).then(response => {
                resolve({
                    message: "PostsConfigs encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            PostsConfigsService.paginate(query).then(response => {
                resolve({
                    message: "PostsConfigs encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            PostsConfigsService.all().then(response => {
                resolve({
                    message: "PostsConfigs encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar PostsConfigs",
                    success: false,
                    error
                });
            })
        })
    }
}
